/* eslint-disable react/function-component-definition */
import React, { useState } from 'react';
import capitalize from 'lodash.capitalize';
import {
  Elements,
  PaymentElement,
  useStripe,
  useElements,
  ExpressCheckoutElement,
} from '@stripe/react-stripe-js';
import {
  hideModal,
  validateAndPostSubscriptionElements,
  invalidateSubscriptionElements,
  toggleCouponField,
  validateCoupon,
  clearError,
  updateFormField,
  displayErrors,
  clearNonFieldErrors,
  processCoinbasePayment,
  resetCoupon,
  clearErrors,
  setSelector,
  initializeStripeRedirect,
} from '../client/subscribe/actions';
import Field from './FieldV2';
import { makePeriodSwitchHandler } from './StripeFormElementsV2';
import CustomTOS from './CustomTOSV2';
import PaymentMethods from './PaymentMethods';

import Spinner from '../clientAdmin/components/Spinner';
import ArrowDropdownIcon from './Icons/arrow-dropdown';
import { formatPrice, getNumber } from '../utils';
import UpsellModal from './UpsellModal';
import AlternatePriceToggle from './AlternatePriceToggle';
import CoinbaseModal from './CoinbaseModal'
import NotAcceptingSignups from './NotAcceptingSignups'
import SignupSection from './SignupSectionV2'
import AppliedDiscountTagIcon from './Icons/applied-discount-tag';
import CloseIcon from './Icons/close-icon';
import CircleStarsIcon from './Icons/circle-stars';
import AboutHeader from './AboutHeader'
import LaunchpassIcon from './Icons/gray-launchpass'
import StripeRedirectModal from './StripeRedirectModal';

const clearInputs = () => {
  const inputs = document.getElementsByTagName('input');
  let i;
  for (i = 0; i < inputs.length; i++) {
    inputs[i].value = '';
  }
};

export const handleModal = (modalType) => (event) => {
  event.preventDefault();
  store.dispatch(hideModal(modalType));
  clearInputs();
  return false;
};

const AutorenewIcon = () => (
  <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M9.25 14.75H10.75V9H9.25V14.75ZM10 7.3C10.2333 7.3 10.425 7.22067 10.575 7.062C10.725 6.904 10.8 6.70833 10.8 6.475C10.8 6.25833 10.725 6.07067 10.575 5.912C10.425 5.754 10.2333 5.675 10 5.675C9.76667 5.675 9.575 5.754 9.425 5.912C9.275 6.07067 9.2 6.25833 9.2 6.475C9.2 6.70833 9.275 6.904 9.425 7.062C9.575 7.22067 9.76667 7.3 10 7.3ZM10 19.5C8.68333 19.5 7.446 19.25 6.288 18.75C5.12933 18.25 4.125 17.575 3.275 16.725C2.425 15.875 1.75 14.8707 1.25 13.712C0.75 12.554 0.5 11.3167 0.5 10C0.5 8.68333 0.75 7.44567 1.25 6.287C1.75 5.129 2.425 4.125 3.275 3.275C4.125 2.425 5.12933 1.75 6.288 1.25C7.446 0.75 8.68333 0.5 10 0.5C11.3167 0.5 12.5543 0.75 13.713 1.25C14.871 1.75 15.875 2.425 16.725 3.275C17.575 4.125 18.25 5.129 18.75 6.287C19.25 7.44567 19.5 8.68333 19.5 10C19.5 11.3167 19.25 12.554 18.75 13.712C18.25 14.8707 17.575 15.875 16.725 16.725C15.875 17.575 14.871 18.25 13.713 18.75C12.5543 19.25 11.3167 19.5 10 19.5ZM10 18C12.2333 18 14.125 17.225 15.675 15.675C17.225 14.125 18 12.2333 18 10C18 7.76667 17.225 5.875 15.675 4.325C14.125 2.775 12.2333 2 10 2C7.76667 2 5.875 2.775 4.325 4.325C2.775 5.875 2 7.76667 2 10C2 12.2333 2.775 14.125 4.325 15.675C5.875 17.225 7.76667 18 10 18Z" fill="#747D85" />
  </svg>

);

const displayCouponField = (e) => {
  e.preventDefault();
  store.dispatch(toggleCouponField(true));
};

const CheckoutV2 = ({
  styles,
  nameOnCard,
  errors,
  channel,
  buttonColor,
  currency = 'usd',
  period,
  status,
  alternatePayment,
  joiningPaymentAmount,
  couponSubmitted,
  couponsEnabled,
  couponValid,
  couponId,
  email,
  requireNameEnabled,
  yearlySelected,
  priceNum,
  couponFieldVisible,
  couponValidating,
  subscribing,
  inputStyles,
  stripeKey,
  stripeKeySCA,
  preview,
  offerName,
  coinbaseEnabled,
  coinbaseCharge,
  modalStyles,
  openModal,
  parsedTrialPeriod,
  isEmbed,
  yearlyPriceNum,
  displaySavedPrice,
  customTOS,
  platform,
  formattedPrices,
  orientation,
  group,
  image,
  displayName,
  isNotV3,
  color,
  trialPeriod,
  customSetting1Enabled,
  quickPaymentType,
  paymentModalOpen,
  isBusinessPageOffer,
  // stripe,
}) => {
  let form = {};

  const [paymentReady, setPaymentReady] = useState(false);
  const [stripeRedirectStatus, setStripeRedirectStatus] = useState(null);
  const [stripeRedirectError, setStripeRedirectError] = useState('');
  const [isStripeRedirectModalOpen, setIsStripeRedirectModalOpen] = useState(false);

  if (typeof store !== 'undefined') {
    const state = store.getState()
    form = state.form;
  }
  // important: this stripe instance is same as one in window.stripe - global one.!!
  const stripe = useStripe();
  const elements = useElements();

  React.useEffect(() => {
    if (!stripe || !elements) {
      return;
    }

    // save elements in windows object and use it from this point onwards.
    window.elements = elements;
  }, [stripe, elements]);

  // Initialize Stripe redirect handling
  React.useEffect(() => {
    const handleStripeRedirect = async () => {
      await initializeStripeRedirect(store.dispatch);
    }

    handleStripeRedirect();
  }, []);

  // Monitor Stripe redirect state through subscribing prop
  React.useEffect(() => {
    // Check for Stripe URL parameters indicating redirect
    const urlParams = new URLSearchParams(window.location.search);
    const hasStripeRedirect = urlParams.has('setup_intent')
      || urlParams.has('payment_intent')
      || urlParams.has('redirect_status')
      || urlParams.has('setup_intent_client_secret')
      || urlParams.has('payment_intent_client_secret');

    if (!hasStripeRedirect) return;

    // If we have Stripe parameters, show modal with loading state
    setIsStripeRedirectModalOpen(true);
    setStripeRedirectStatus('loading');

    // Watch for subscribing prop changes to detect when processing completes
    if (!subscribing) {
      // Check if there are any errors in the Redux store
      const state = store.getState();
      // Safely check if errors exists and is an array with items
      if (state.form && Array.isArray(state.form.errors) && state.form.errors.length > 0) {
        const latestError = state.form.errors[state.form.errors.length - 1];
        setStripeRedirectStatus('error');
        setStripeRedirectError(
          latestError?.message ||
          (typeof latestError === 'string' ? latestError : 'Payment processing failed. Please try again.')
        );
      } else {
        // No errors and not subscribing means successful completion
        setStripeRedirectStatus('success');
      }
    }
  }, [subscribing]);

  React.useEffect(() => {
    const handleMessage = (event) => {
      if (event.data === 'selectMonthly') {
        store.dispatch({ type: 'SELECT_MONTHLY' });
      }
      if (event.data === 'selectYearly') {
        store.dispatch({ type: 'SELECT_YEARLY' });
      }
      if (
        typeof event.data === 'string'
        && event.data?.startsWith('purchaseRedirectUrl')
      ) {
        setPurchaseRedirectUrl(event.data.split(' ')[1]);
      }
    };
    window.addEventListener('message', handleMessage);
    if (isEmbed) parent.postMessage('initIframe', '*');

    return () => {
      window.removeEventListener('message', handleMessage);
    };
  }, [isEmbed]);

  const [isHalfVisible, setIsHalfVisible] = React.useState(true); // Assuming visible initially
  const targetElementRef = React.useRef(null);

  React.useEffect(() => {
    const checkVisibility = () => {
      if (!targetElementRef.current) {
        return;
      }

      if (window.innerWidth >= 640) {
        setIsHalfVisible(true);
        return; // Do not proceed if the screen width is 640px or more
      }

      const rect = targetElementRef.current.getBoundingClientRect();
      const elementHeight = rect.bottom - rect.top;

      // Calculate visible portion of the element
      const visibleTop = Math.max(0, rect.top);
      const visibleBottom = Math.min(window.innerHeight, rect.bottom);
      const visibleHeight = visibleBottom - visibleTop;

      // Check if less than 50% of the element is visible
      setIsHalfVisible(!(visibleHeight < elementHeight / 4));
    };

    if (!isEmbed) {
      window.addEventListener('scroll', checkVisibility, { passive: true });
      window.addEventListener('touchstart', checkVisibility, { passive: true });

      // Initial check in case the element's visibility status needs to be determined on initial render
      checkVisibility();
    }

    return () => {
      window.removeEventListener('scroll', checkVisibility);
      window.removeEventListener('touchstart', checkVisibility);
    };
  }, []);

  const [paymentMethod, setPaymentMethod] = React.useState('card');
  const [upsellModalOpen, setUpsellModalOpen] = React.useState(false);
  const [isCreditCardValid, setCreditCardValid] = React.useState(false);
  const [couponValue, setCouponValue] = React.useState(couponId);
  const [purchaseRedirectUrl, setPurchaseRedirectUrl] = React.useState(null);

  // NOTE: In future used controlled compoenent, DOM access is antipattern
  const handleCouponClick = (event) => {
    event.preventDefault();
    store.dispatch(validateCoupon(couponValue));
  };

  const handleResetCoupon = () => {
    store.dispatch(resetCoupon())
    store.dispatch(toggleCouponField(false))
    store.dispatch(updateFormField('coupon', null))
    setCouponValue('');
  }

  const handleKeyPress = (event) => {
    if (event.key == 'Enter') {
      store.dispatch(validateCoupon(couponValue));
      event.preventDefault();
    }

    return false;
  };

  errors = errors || [];

  const formErrors = errors.reduce((obj, { field, message }) => {
    obj[field || 'nonFieldError'] = message;
    return obj;
  }, {});

  React.useEffect(() => {
    // Hide upsell modal when error happens
    // So user can see error on card submission form
    if (formErrors.nonFieldError && upsellModalOpen) {
      setUpsellModalOpen(false);
    }
  }, [formErrors.nonFieldError, upsellModalOpen]);

  React.useEffect(() => {
    if (quickPaymentType === 'crypto') {
      setPaymentMethod('crypto')
    } else {
      setPaymentMethod('card')
    }
  }, [quickPaymentType])

  const handleSubmit = async (event) => {
    if (event.preventDefault) {
      event.preventDefault();
    }

    if (paymentMethod === 'crypto') {
      return handleCryptoPayment();
    }

    const cardElement = elements.getElement('card');
    store.dispatch(updateFormField('card', cardElement));

    if (paymentMethod !== 'card' && paymentMethod !== 'crypto') {
      store.dispatch(updateFormField('paymentMethod', paymentMethod))
    }

    if (event?.elementType !== 'expressCheckout') {
      // Upsell Modal open with these conditions, otherwise skip to submit
      if (
        !yearlySelected
      && alternatePayment
      && !upsellModalOpen
      && displaySavedPrice
      && !formErrors.nonFieldError
      ) {
        const validationErrors = invalidateSubscriptionElements({ provider: 'stripe' }, 'V4');
        if (validationErrors.length) {
          return store.dispatch(displayErrors(validationErrors));
        }

        setUpsellModalOpen(true);
        return;
      }
    }

    if (typeof clicky !== 'undefined') {
      clicky.goal('paidInviteSignup');
    }
    if (window.lp_event) window.lp_event('initiate_checkout');

    store.dispatch(
      validateAndPostSubscriptionElements(
        {
          expressCheckout: event?.elementType === 'expressCheckout',
          email: event?.billingDetails ? event?.billingDetails?.email : email,
          provider: 'stripe',
          purchaseRedirectUrl,
          paymentEvent: event?.elementType === 'expressCheckout' ? event : null,
        },
        'V4',
        isEmbed,
      ),
    );
  };

  const onAlternatePriceToggleChange = () => {
    if (!alternatePayment) return;

    store.dispatch({ type: yearlySelected ? 'SELECT_MONTHLY' : 'SELECT_YEARLY' })
  }

  // Crypto
  const handleCryptoPayment = () => {
    store.dispatch(
      validateAndPostSubscriptionElements({
        provider: 'coinbase',
      }, 'V4'),
    )
  }

  const processCryptoPayment = (data) => {
    store.dispatch(processCoinbasePayment(data, coinbaseCharge, 'v3'))
  }

  const onQuickPayClick = ({ resolve }) => {
    const options = {
      emailRequired: true,
    };

    const state = store.getState()
    const errs = invalidateSubscriptionElements({
      email: state.form.email,
    }, 'V4')

    if (errs.length) {
      store.dispatch(displayErrors(errs));
      return;
    }

    resolve(options);
  };

  const onQuickPayConfirm = async (event) => handleSubmit(event);

  const isTrialEnabled = parsedTrialPeriod > 0;
  const isInputDisabled = status === 'Disabled' || status === 'Hidden';
  const isJoinBtnDisabled = (status === 'Disabled' || !isCreditCardValid || subscribing) && paymentMethod !== 'crypto';

  const additionalTransactionDetailsArea = (
    <>
      {!isTrialEnabled && (
        <div className="flex flex-col gap-4 py-3 px-4 rounded-lg self-stretch mb-4 border border-gray-400 px-[16px] py-[13px] rounded-md bg-white">
          <div className="flex items-center justify-between gap-2">
            <div className="text-left flex-1 text-black-pearl font-semibold text-sm trans-label">
              {formattedPrices.simpleText}
            </div>
            <div className={`${styles.TierModal?.transValue} flex items-center text-black-pearl gap-1 font-semibold text-sm`}>
              {couponSubmitted && couponValid && (
                <span className="line-through font-normal original-price">{formattedPrices.currentPrice}</span>
              )}
              {formattedPrices.currentPriceAfterCoupon}
            </div>
          </div>
        </div>
      )}

      {isTrialEnabled && (
        <div className="flex flex-col self-stretch border border-gray-400 px-[16px] py-[13px] rounded-md bg-alice-blue-500 gap-2">
          <div className="flex items-center justify-between gap-2">
            <div className="text-left flex-1 text-gray-100 font-medium text-xs trans-label">
              {`${formattedPrices.period !== 'one-time' ? 'Recurring ' : ''}${capitalize(formattedPrices.period)}${formattedPrices.period !== 'one-time' ? ' Subscription' : ''}`}
            </div>
            <div className={`${styles.TierModal?.transValue} flex items-center gap-1 text-fiorid-500 font-medium text-xs items-center`}>
              {couponSubmitted && couponValid && (
                <span className="line-through font-normal original-price">{formattedPrices.currentPrice}</span>
              )}
              {formattedPrices.currentPriceAfterCoupon}
            </div>
          </div>
          <hr className="border-top border-top-gray-400 my-[5px] mx-[0px]" />
          <div className="flex items-center justify-between gap-2">
            <div className="flex-1 text-left text-gray-100 font-normal text-sm total-due-after-trial">
              Total due after trial
            </div>
            <div className="text-fiorid-500 font-normal text-sm total-due-after-trial-amount">
              {formattedPrices.currentPriceAfterCoupon}
            </div>
          </div>
          <div className="flex items-center justify-between gap-2">
            <div className="flex-1 text-left text-black-pearl font-semibold text-sm total-due-today">Total due today</div>
            <div className="text-left text-black-pearl font-semibold text-sm total-due-today-amount">
              {formatPrice(0.00, currency)}
            </div>
          </div>
        </div>
      )}
    </>
  );

  const couponArea = (
    <>
      {couponsEnabled && !couponFieldVisible && !couponSubmitted && (
        <div
          tabIndex="-1"
          className={`${styles.TierModal.coupon} flex justify-between items-center mb-0`}
          name="displayCoupon"
          role="button"
        >
          <div className="text-fiord-500 text-sm items-center">
            Have a coupon?
          </div>

          <div className="flex gap-1 items-center">
            <a href="#" name="applyCoupon" className="text-primary text-sm font-medium" onClick={displayCouponField} disabled={isInputDisabled}>Click to apply it.</a>
            <ArrowDropdownIcon />
          </div>
        </div>
      )}

      {couponsEnabled && couponFieldVisible && (
        <div>
          {!couponValid && (
            <div style={{ marginBottom: 0 }}>
              <div className={styles.TierModal.enterCouponWrapper} id="couponButton">
                <Field styles={styles.Form} className="relative" htmlFor="couponField">
                  <input
                    className="!w-full !rounded-r-lg !text-base !py-2"
                    id="couponField"
                    type="text"
                    name="coupon"
                    value={couponValue}
                    onChange={(e) => setCouponValue(e.target.value)}
                    placeholder="Enter code"
                    onKeyPress={handleKeyPress}
                    onFocus={(e) => {
                      e.target.placeholder = '';
                    }}
                    onBlur={(e) => {
                      e.target.placeholder = 'Enter code...';
                    }}
                    style={{
                      borderTopRightRadius: '8px !important',
                      borderBottomRightRadius: '8px !important',
                    }}
                  />

                  <button
                    type="button"
                    name="couponButton"
                    className={`
                      ${styles.TierModal.applyCouponButton}
                      !border-0 pr-2 !pl-2 !absolute !right-0 !text-fiord-500 !font-medium
                      !shadow-none !text-sm !top-0 !w-auto !bg-transparent
                    `}
                    style={{ boxShadow: 'none' }}
                    onClick={handleCouponClick}
                  >
                    {!couponValidating ? (
                      'Apply'
                    ) : (
                      <Spinner color="#000" size={25} />
                    )}
                  </button>
                </Field>
              </div>
            </div>
          )}

          <div>
            {couponValid && couponSubmitted && (
              <div className="flex justify-between items-center">
                <div className="flex item-center gap-2">
                  <div className="text-fiord-500 space-around gap-2 font-medium text-sm uppercase flex items-center bg-light-gray-100 rounded-md py-[4px] px-[8px]">
                    <AppliedDiscountTagIcon />
                    <span>
                      {`${couponId}`}
                    </span>
                    <span className="hover:cursor-pointer" type="button" id="resetCoupon" onClick={handleResetCoupon}>
                      <CloseIcon />
                    </span>
                  </div>

                  <div className="text-sm flex items-center text-fiord-500 font-normal">
                    {formattedPrices.discountDisplay}
                  </div>
                </div>
                <div className="text-fiord-500 font-medium text-sm">
                  {formattedPrices.currentPriceAfterCoupon}
                </div>
              </div>
            )}
            {!couponValid && couponSubmitted && (
              <p name="couponInvalid" className="text-red-100 text-xs text-left py-[5px] px-0 font-normal">
                This code is invalid/expired.
              </p>
            )}
          </div>
        </div>
      )}
    </>
  );

  const transactionDetailsArea = (
    <div className="flex flex-col gap-4 w-full">
      {/* Alternate price toggle */}
      {alternatePayment && (
      <AlternatePriceToggle
        defaultChecked={yearlySelected}
        checked={yearlySelected}
        onChange={onAlternatePriceToggleChange}
        saveAmount={formattedPrices.yearlyDiscountSavings}
        label="with annual billing"
      />
      )}
      {couponArea}
      {additionalTransactionDetailsArea}
      {period !== 'one-time' && customSetting1Enabled !== false && (
        <div className="hidden md:flex lg:flex gap-2">
          <div className="w-[20px]">
            <AutorenewIcon />
          </div>
          <div className="text-gray-100 text-sm text-left">
            {formattedPrices.autoRenewText}
          </div>
        </div>
      )}
    </div>
  )

  const closeStripeRedirectModal = () => {
    setIsStripeRedirectModalOpen(false);
    // Clean up the URL by removing Stripe parameters
    if (window.history && window.history.replaceState) {
      const cleanUrl = window.location.pathname + window.location.hash;
      window.history.replaceState({}, document.title, cleanUrl);
    }
  };

  if (status === 'hidden') {
    return <NotAcceptingSignups />;
  }

  return (
    <>
      {upsellModalOpen && (
        <UpsellModal
          buttonColor={buttonColor}
          onConfirm={handleSubmit}
          subscribing={subscribing}
          setModalOpen={setUpsellModalOpen}
          period={period}
          displaySavedPrice={displaySavedPrice}
          isInputDisabled={isInputDisabled}
          formattedPrices={formattedPrices}
        />
      )}

      {isStripeRedirectModalOpen && (
        <StripeRedirectModal
          isOpen={isStripeRedirectModalOpen}
          onClose={closeStripeRedirectModal}
          status={stripeRedirectStatus}
          errorMessage={stripeRedirectError}
        />
      )}

      <form
        ref={targetElementRef}
        id="payment-form"
        onSubmit={handleSubmit}
        className={`
          ${styles.TierModal.form} flex
          ${orientation === 'horizontal' ? `gap-3 ${!quickPaymentType ? 'md:flex-row' : ''} flex-col md:w-auto lg:w-auto w-full` : 'flex-col w-full gap-3'}
          ${quickPaymentType ? 'flex flex-col items-center max-w-[600px]! pl-0' : ''}
        `}
      >
        {/* Left side when orientation is horizontal */}
        {orientation === 'horizontal' && (
          <div className={`flex flex-col gap-3 md:flex-1 lg:flex-1 md:p-[25px] lg:px-[40px] px-[16px] py-[16px] sm:py-[32px] bg-alice-blue-500 rounded-lg ${quickPaymentType && 'w-full'}`}>
            <div className="flex items-center flex-col self-center justify-center w-full max-w-[520px]">
              <div className="w-full flex items-center mb-[15px]">
                <AboutHeader
                  theme="light"
                  styles={modalStyles.AboutHeader}
                  profileIconsStyles={styles.ProfileIcons}
                  channel={channel}
                  group={group}
                  image={image}
                  displayName={displayName}
                  offerName={offerName}
                  color={color}
                  status={status}
                  preview={preview}
                  trialPeriod={parsedTrialPeriod}
                  period={period}
                  isNotV3={isNotV3}
                  isV4
                />
              </div>

              {transactionDetailsArea}
            </div>
          </div>
        )}

        {/* Right side when orientation is horizontal */}
        <div className={`flex flex-col gap-3 ${orientation === 'horizontal' && `flex-1 ${quickPaymentType ? 'w-full py-2 px-0' : 'max-w-[520px] py-[16px] w-full px-3'} md:self-auto self-center`}`}>
          {isTrialEnabled && (
          <div className="flex items-center justify-between bg-blue-200 border hidden sm:flex border-blue-100 rounded-lg py-[8px] px-[24px]">
            <div className="text-blacl-pearl font-semibold text-base gap-1 trial-period">
              {`Free for ${parsedTrialPeriod} day${parsedTrialPeriod > 1 ? 's' : ''}`}
            </div>
            <CircleStarsIcon />
          </div>
          )}

          {!quickPaymentType && (
            <PaymentMethods
              cryptoEnabled={coinbaseEnabled}
              onSelect={(method) => setPaymentMethod(method)}
            />
          )}

          {/* {(!quickPaymentType || quickPaymentType === 'crypto') && ( */}
            <>
              {status !== 'Hidden' && !quickPaymentType && paymentMethod !== 'crypto' && (
                <ExpressCheckoutElement
                  onClick={onQuickPayClick}
                  onConfirm={onQuickPayConfirm}
                  onReady={(event) => {
                    store.dispatch(setSelector({
                      expressCheckoutOptions: event,
                    }))
                  }}
                  options={{
                    layout: {
                      maxColumns: 1,
                      maxRows: 10,
                    }
                  }}
                />
              )}
              <Field
                styles={styles.Form}
                htmlFor="email"
                error={formErrors.email}
              >
                <input
                  type="text"
                  name="email"
                  placeholder="Enter email"
                  disabled={isInputDisabled}
                  className="disabled:!bg-white disabled:!opacity-50 !text-base !py-2 !rounded-md"
                  defaultValue={email}
                  onChange={(e) => {
                    store.dispatch(clearError('email'));
                    store.dispatch(clearNonFieldErrors());
                    store.dispatch(clearError('emailConfirm'));
                    store.dispatch(updateFormField('email', e.target.value));
                    store.dispatch(
                      updateFormField('emailConfirm', e.target.value),
                    );
                  }}
                  style={{ fontSize: '16px !important' }}
                />
              </Field>
            </>
          {/* )} */}

          {/* only visible for card payment methods */}
          {paymentMethod !== 'crypto' && !quickPaymentType && (
            <>
              {requireNameEnabled && paymentMethod === 'card' && (
                <Field
                  styles={styles.Form}
                  htmlFor="nameOnCard"
                  error={formErrors.nameOnCard}
                >
                  <input
                    name="nameOnCard"
                    type="text"
                    defaultValue={nameOnCard}
                    disabled={isInputDisabled}
                    placeholder="Name on card"
                    className={`${
                      [
                        formErrors.nameOnCard || '',
                        inputStyles,
                        styles.name,
                      ].join(' ')
                    } disabled:!bg-white disabled:!opacity-50 !text-base !py-2 !rounded-md`}
                    onChange={(e) => {
                      store.dispatch(clearError('nameOnCard'));
                      store.dispatch(updateFormField('nameOnCard', e.target.value));
                    }}
                  />
                </Field>
              )}
              {status !== 'Hidden' && (!quickPaymentType || quickPaymentType === 'crypto') &&(
                <div className="min-h-[350px]">
                  <PaymentElement
                    id="payment-element"
                    onReady={(event) => {
                      setPaymentReady(true);
                    }}
                    onChange={(event) => {
                      setCreditCardValid(event.complete);
                      setPaymentMethod(event.value.type);
                    }}
                  />
                </div>
              )}
            </>
          )}

          {formErrors.nonFieldError && (
            <div className={styles.TierModal.controlError}>
              {formErrors.nonFieldError}
            </div>
          )}

          {orientation !== 'horizontal' && transactionDetailsArea}
          <div className={`${orientation === 'horizontal' ? 'flex flex-col gap-2' : ''}`}>
            <Field
              styles={styles.Form}
              htmlFor="customTOS"
              error={formErrors.customTOS}
            >
              <CustomTOS
                customTOS={customTOS}
                isV3
                formErrors={formErrors}
                styles={styles.TierModal}
                name="customTOS"
                checked={form?.tosAccepted}
                onChange={(checked) => {
                  store.dispatch(clearError('customTOS'));
                  store.dispatch(clearNonFieldErrors());
                  store.dispatch(updateFormField('tosAccepted', checked));
                }}
              />
            </Field>

            {period !== 'one-time' && customSetting1Enabled !== false && (
              <div className="flex md:hidden lg:hidden gap-2">
                {/* <div className="w-[20px]">
                  <AutorenewIcon />
                </div> */}
                <div className="text-gray-100 text-xs text-left">
                  {formattedPrices.autoRenewText}
                </div>
              </div>
            )}
          </div>

          <SignupSection
            status={status}
            isBusinessPageOffer={isBusinessPageOffer}
            disabled={isJoinBtnDisabled && !preview}
            subscribing={subscribing}
            buttonColor={buttonColor}
            trialPeriod={parsedTrialPeriod}
            customTOS={customTOS}
            formErrors={formErrors}
            showOnMobile
            styles={styles}
            platform={platform}
            subtotal={isTrialEnabled ? formatPrice('0.00', currency) : formattedPrices.currentPriceAfterCoupon}
            isHalfVisible={isHalfVisible}
            onSubmit={handleSubmit}
            isEmbed={isEmbed}
            quickPaymentType={quickPaymentType}
            currency={currency}
            stripe={stripe}
            channel={channel}
            period={period}
            offerName={offerName}
            formattedPrices={formattedPrices}
            paymentModalOpen={paymentModalOpen}
          />
        </div>

        {/* Coinbase Modals */}
        {openModal
          && (openModal.modalType === 'coinbase'
          || openModal.modalType === 'coinbase-close')
          && (
            <CoinbaseModal
              styles={styles}
              modalStyles={modalStyles}
              openModal={openModal}
              charge={openModal.modalProps}
              handlePayment={processCryptoPayment}
            />
          )}
      </form>
    </>
  );
};

function EmbedModalContainer({
  children, onClose, trialPeriod, quickPaymentType,
}) {
  const isIos = [
    'iPhone Simulator',
    'iPhone',
  ].includes(navigator.platform)

  return (
    <div
      className="modal fixed w-full top-0 left-0 h-screen overflow-auto"
    >
      <div className="flex justify-center items-center flex-col h-full md:px-[30px] p-0">
        <div
          className={`
          ${trialPeriod > 0 ? 'sm:pb-[8px] pb-[160px]' : 'sm:pb-[8px]'} 
          modal-container bg-white w-full h-full md:h-auto ${quickPaymentType ? 'md:max-w-[600px]' : 'md:max-w-[1040px]'} m-auto rounded-none sm:rounded-lg shadow-lg z-50 md:p-0 p-0 relative overflow-auto
          flex flex-col justify-between md:block
        `}
          style={{
            ...(isIos ? { paddingBottom: '80px' } : {}),
          }}
        >
          <div className="modal-content p-0 md:py-[50px] text-left md:px-[50px] relative">
            <div type="button" onClick={onClose} className="modal-close cursor-pointer z-50 absolute top-[25px] right-[25px] text-sm cursor-pointer flex flex-col items-center ">
              <svg width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
                <mask id="mask0_1206_27145" style={{ maskType: 'alpha' }} maskUnits="userSpaceOnUse" x="0" y="0" width="24" height="25">
                  <rect y="0.792969" width="24" height="24" fill="#D9D9D9" />
                </mask>
                <g mask="url(#mask0_1206_27145)">
                  <path d="M7.86842 18.793L7 17.9245L11.6316 13.293L7 8.66139L7.86842 7.79297L12.5 12.4245L17.1316 7.79297L18 8.66139L13.3684 13.293L18 17.9245L17.1316 18.793L12.5 14.1614L7.86842 18.793Z" fill="#747D85" />
                </g>
              </svg>
            </div>

            {children}
          </div>
          <div className="flex w-full text-xs gap-1 text-gray-200 items-center justify-start text-center px-[16px] self-center md:px-0">
            <div className="flex items-center py-[10px] border-t border-t-gray-400 w-full justify-center gap-1">
              <a target="_blank" href="/" className="text-gray-200 no-underline flex items-center gap-1">
                Powered by
                {' '}
                <span className="font-semibold">LaunchPass</span>
                {' '}
                <LaunchpassIcon />
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

const getApplePayConfig = (props) => {
  const {
    offerName,
    period,
    parsedTrialPeriod,
    formattedPrices,
    country,
    currency,
  } = props;

  const finalAmount = (
    !props.trialPeriod || !parseInt(props.trialPeriod)
  ) ? Number((getNumber(formattedPrices.currentPriceAfterCoupon) * 100).toFixed(0)) 
    : 0;

  const baseConfig = {
    paymentDescription: offerName,
    merchantCountry: country || 'US',
    merchantCapabilities: ['supports3DS'],
    buttonType: 'subscribe',
    merchantIdentifier: 'merchant.com.launchpass',
    buttonStyle: 'black',
    buttonLanguage: 'en',
    requestShipping: false,
    supportedCountries: [country || 'US'],
    supportedNetworks: ['visa', 'mastercard', 'amex', 'discover'],
  };

  // Add recurring payment config for subscription payments
  if (period !== 'one-time') {
    baseConfig.recurringPaymentRequest = {
      paymentDescription: `${offerName} Subscription`,
      managementURL: 'https://launchpass.com/portal',
      regularBilling: {
        label: formattedPrices.simpleText,
        amount: finalAmount,
        billingAgreement: `You will be charged ${formattedPrices.currentPriceAfterCoupon} ${period === 'monthly' ? 'per month' : 'per year'}`,
      }
    };
  }

  // Add trial period config if applicable
  if (parsedTrialPeriod > 0) {
    baseConfig.paymentRequest = {
      label: `${parsedTrialPeriod}-day free trial`,
      amount: 0,
      billingAgreement: `After your ${parsedTrialPeriod}-day free trial, you will be charged ${formattedPrices.currentPriceAfterCoupon} ${period === 'monthly' ? 'per month' : 'per year'}`
    };
  }

  return baseConfig;
};

function InjectedCheckoutV2(props) {
  const stripe = props.stripe?._apiKey && props.stripe?._apiKey !== 'undefined'
    ? props.stripe
    : null;

  const {
    period, alternatePayment, isEmbed, currency,
  } = props;
  let modalPeriod = period;

  React.useEffect(() => {
    if (isEmbed && alternatePayment) {
      modalPeriod = 'monthly';
      makePeriodSwitchHandler('monthly');
    }
  }, [period]);

  const [modalOpen, setModalOpen] = React.useState(true);

  const closeModal = () => {
    const paymentEmbedEl = document.getElementById('payment-embed');
    if (paymentEmbedEl) {
      paymentEmbedEl.style.display = 'none';
      store.dispatch(setSelector({
        paymentModalOpen: false,
      }))
    }

    if (isEmbed) {
      parent.postMessage('closeIframe', '*');
      return;
    }

    if (props.subscribing) return;

    clearInputs();
    store.dispatch(clearErrors());
    // reset coupon if error and close modal
    if (!props.couponValid && props.couponSubmitted) {
      store.dispatch(resetCoupon());
    }
    setModalOpen(false);
  }

  const amount = (
    !props.trialPeriod
    || !parseInt(props.trialPeriod))
    ? Number((getNumber(props.formattedPrices.currentPriceAfterCoupon) * 100).toFixed(0))
    : 0

  const supportedPaymentOptions = {
    trial: 'setup',
    'one-time': 'payment',
    monthly: 'subscription',
    yearly: 'subscription',
    weekly: 'subscription',
    daily: 'subscription',
    semiannual: 'subscription',
    quarterly: 'subscription',
    biweekly: 'subscription',
  };

  let finalAmount = amount;
  if (props.period !== 'one-time') {
    finalAmount = props.parsedTrialPeriod ? 0 : amount;
  }

  return (
    <Elements
      stripe={stripe}
      options={{
        mode: supportedPaymentOptions[props.period],
        amount: finalAmount,
        loader: 'always',
        currency,
        appearance: {
          labels: 'above',
        },
        setup_future_usage: 'off_session',
      }}
    >
      {isEmbed && modalOpen && (
        <EmbedModalContainer
          isOpen={modalOpen}
          onClose={closeModal}
          trialPeriod={props.parsedTrialPeriod}
          quickPaymentType={props.quickPaymentType}
        >
          <CheckoutV2
            {...props}
            stripe={stripe}
            period={modalPeriod}
            orientation="horizontal"
          />
        </EmbedModalContainer>
      )}
      {!isEmbed && (
        <CheckoutV2
          {...props}
          stripe={stripe}
          period={modalPeriod}
        />
      )}
    </Elements>
  );
}

export default InjectedCheckoutV2;
