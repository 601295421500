import React, { forwardRef } from 'react';
import { getNumber,formatPrice } from '../../../utils';

const OfferCard = forwardRef(({
  offer, selected, onSelect, tabbed, className,
}, ref) => {
  const {
    offerName, price, currency, period, id, displayName, trialPeriod
  } = offer;

  return (
    <div
      ref={ref} // Attach the ref to the root div
      data-asset-id={id}
      className={`${className ?? ''} w-full flex flex-col p-3 justify-center 
        ${tabbed
        ? `${selected ? 'border-b-2 border-primary' : 'border-b-2 border-white'}`
        : `rounded-xl border border-gray-400 ${selected ? 'border-primary border' : ''} hover:border-primary hover:border`
        }
        hover:cursor-pointer group`}
      style={{
        ...(!tabbed && selected ? {
          boxShadow: '0px 2px 6px 0px rgba(0, 0, 0, 0.16)',
        } : {}),
      }}
      onClick={() => onSelect(id)}
    >

      <div className={`text-sm md:text-base ${tabbed && !selected ? 'font-normal' : 'font-semibold'} text-black-pearl whitespace-nowrap ${selected ? 'text-black-pearl font-semibold' : 'text-fiord-500'} overflow-hidden text-ellipsis group-hover:text-black-pearl group-hover:font-semibold`}>
        {(offerName || displayName)}
      </div>

      <div className={`text-xs md:text-sm  ${!selected ? 'text-fiord-500' : 'text-black-pearl'} overflow-hidden text-ellipsis group-hover:text-black-pearl`}>
        {period === 'free' ? 'Free' : `${formatPrice((getNumber(price) / 100).toFixed(2), currency)}/${period} ${trialPeriod && trialPeriod !== '0' ? `(${trialPeriod}-day trial)` : ''}`}
      </div>
    </div>
  );
});

export default OfferCard;
