/* eslint-disable default-param-last */
/* eslint-disable no-useless-escape */
import 'whatwg-fetch'

import { fullstoryInit, fullstoryIdentify } from '../../lib/fullstory'

export const REQUEST_SUBSCRIPTION = 'REQUEST_SUBSCRIPTION'
export const RECEIVE_SUBSCRIPTION = 'RECEIVE_SUBSCRIPTION'
export const VALIDATE_FORM = 'VALIDATE_FORM'
export const INVALIDATE_FORM = 'INVALIDATE_FORM'
export const CLEAR_ERROR = 'CLEAR_ERROR'
export const CLEAR_NON_FIELD_ERRORS = 'CLEAR_NON_FIELD_ERRORS'
export const CLEAR_ERRORS = 'CLEAR_ERRORS'
export const ADDITIONAL_ERROR = 'ADDITIONAL_ERROR'
export const OPEN_MODAL = 'OPEN_MODAL'
export const HIDE_MODAL = 'HIDE_MODAL'
export const TOGGLE_COUPON_FIELD = 'TOGGLE_COUPON_FIELD'
export const COUPON_VALID = 'COUPON_VALID'
export const COUPON_INVALID = 'COUPON_INVALID'
export const COUPON_VALIDATING = 'COUPON_VALIDATING'
export const COUPON_RESET = 'COUPON_RESET'
export const CLICK_TOS = 'CLICK_TOS'
export const UPDATE_FORM_FIELD = 'UPDATE_FORM_FIELD'
export const SET_COINBASE_CHARGE = 'SET_COINBASE_CHARGE'
export const UPDATE_PAGE = 'UPDATE_PAGE'
export const SET_PAGE = 'SET_PAGE'
export const SET_SELECTOR = 'SET_SELECTOR'
export const UPDATE_COUPON_CONFIG = 'UPDATE_COUPON_CONFIG'
export const SET_OFFER = 'SET_OFFER'

export const SET_STRIPE = 'SET_STRIPE'
const emailRegExp = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

export const isEmail = (email) => emailRegExp.test(email)

const noop = (op) => op

export const setOffer = (offer) => noop({
  type: SET_OFFER,
  offer,
})

export const updateCouponConfig = (coupon) => noop({
  type: UPDATE_COUPON_CONFIG,
  coupon,
});

export const setSelector = (data) => noop({
  type: SET_SELECTOR,
  data,
});

export const updatePage = (page) => noop({
  type: UPDATE_PAGE,
  page,
})

export const setPage = (page) => noop({
  type: SET_PAGE,
  page,
})


export const setStripe = (stripe) => noop({
  type: SET_STRIPE,
  stripe,
})

export const openModal = (modalType, modalProps) => noop({
  type: OPEN_MODAL,
  modalType,
  modalProps,
})

export const hideModal = (modalType) => noop({
  type: HIDE_MODAL,
  modalType,
})

export const toggleCouponField = (visible) => ({
  type: TOGGLE_COUPON_FIELD,
  visible,
})

export const updateFormField = (field, value) => ({
  type: UPDATE_FORM_FIELD,
  field,
  value,
})

export const resetCoupon = () => ({
  type: COUPON_RESET,
})

const couponValidating = (status) => ({
  type: COUPON_VALIDATING,
  status,
})

const couponInvalid = () => ({
  type: COUPON_INVALID,
})

const couponValid = (coupon) => ({
  type: COUPON_VALID,
  coupon,
})

const requestSubscription = () => noop({
  type: REQUEST_SUBSCRIPTION,
})

const receiveSubscription = (json) => noop({
  type: RECEIVE_SUBSCRIPTION,
  results: json.data,
  receivedAt: Date.now(),
})

export const displayErrors = (errors) => noop({
  type: INVALIDATE_FORM,
  errors,
})

export const clearError = (field) => noop({
  type: CLEAR_ERROR,
  field,
})

export const clearNonFieldErrors = () => noop({
  type: CLEAR_NON_FIELD_ERRORS,
})

export const clearErrors = () => noop({
  type: CLEAR_ERRORS,
})

export const additionalError = (error) => noop({
  type: ADDITIONAL_ERROR,
  error,
})

export const setCoinbaseCharge = (value) => ({
  type: SET_COINBASE_CHARGE,
  value,
})

// Subscriptions

const postSubscription = ({
  form,
  paymentOption,
  pageId,
  customRedirect,
  convertCode,
  validCoupon,
  pageCurrency,
}) => async (dispatch) => {
  try {
    dispatch(requestSubscription())

    const {
      email, cardNumber, cvc, date, affiliate, nameOnCard,
    } = form

    fullstoryInit()
    fullstoryIdentify(email, email, email)

    const stripe = new Promise((resolve, reject) => {
      Stripe.card.createToken({
        number: cardNumber,
        cvc,
        exp: date,
        currency: pageCurrency,
      }, (status, response) => {
        if (response.error) {
          reject(response.error.message)
        } else {
          resolve(response.id)
        }
      })
    })

    const token = await stripe

    const purchaseRedirectUrl = (new URLSearchParams(window.location.search))?.get('redirect');

    const data = {
      email,
      token,
      paymentOption,
      pageId,
      affiliate,
      validCoupon,
      nameOnCard,
      purchaseRedirectUrl
    }

    const response = await fetch(`${apiUrl}/user`, {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      credentials: 'include',
      body: JSON.stringify(data),
    })

    const results = await response.json()

    data.stripeCustomerId = results.stripeCustomerId

    if (results.status === 'ok') {
      trackPurchase(results);
      if (convertCode) {
        eval(decodeURIComponent(convertCode))
      }
      if (results.discordInviteLink) {
        return window.location.replace(results.discordInviteLink)
      }
      if (customRedirect) {
        window.location.replace(customRedirect.startsWith('http') ? customRedirect : `http://${customRedirect}`)
      } else {
        dispatch(receiveSubscription(results))
      }
    } else {
      dispatch(additionalError(results))
    }
  } catch (error) {
    console.error(error)
    dispatch(additionalError(error))
  }
}

const handleCardAuth = async (paymentIntent) => {
  const resp = await stripe.handleCardPayment(paymentIntent.client_secret);
  if (resp.error) {
    console.error('handleCardAuth Error', resp.error.message);
  }

  return resp;
}

const handleCardSetupAuth = async (setupIntent) => {
  const resp = await stripe.handleCardSetup(setupIntent.client_secret);
  if (resp.error) {
    console.error('handleCardAuth Error', resp.error.message);
  }

  return resp;
}

export const handleRedirect = (url) => {
  try {
    (window.top || window).location.href = url
  } catch (error){
    // fallback in case iframe does not have permissions
    // to redirect (iframe with sandbox attrs, etc)
    console.error(`Redirect error`, error)
    document.location.href = url
  }
}
function amountToDollars(amount) {
  if (amount == null || amount === undefined) return 0

  return Number((parseInt(amount, 10) / 100).toFixed(2))
}

const getPortalRedirectUrl = (params, path) => {
  return Object
    .entries(params || {})
    .reduce((url, [key, value]) => {
      url.searchParams.set(key, value)
      return url
      }, new URL(path || `${document.location.origin}/portal`)).toString()
}
const handlePortalRedirect = (params, path) => {
  handleRedirect(getPortalRedirectUrl(params, path))
}

const trackPurchase = (data) => {
  
  const pathSegments = window.location.pathname.split('/').filter(Boolean); // Filter to remove empty segments
  let pageMethod = pathSegments[pathSegments.length - 1]; // Get last segment without modifying array

  if (pathSegments.length === 1) {
    pageMethod = 'bp';
  } else if (!['v1', 'v2', 'v3', 'v4', 'iframe', 'embed'].includes(pageMethod)) {
    pageMethod = 'v2';
  }

  const lpVersion = store.getState().page?.lpVersion || '1.0'
  let trialing = false;
  if (!data) return;

  if (data.domain === null || data.domain == undefined || data.domain == '') {
    console.log('ERROR track purchase with no domain')
  }
  if (data.price === null || data.price == undefined || data.price == '') {
    console.log('ERROR track purchase with no price')
  }
  // data.stripeCustomerId = results.stripeCustomerId
  if (data.paymentIntent) {
    data.tid = data.paymentIntent.id
    data.type = 'one-time'
  }

  if (data.subscription) {
    data.tid = data.subscription.id
    data.type = data.period

    if (data.subscription.status === 'trialing') {
      trialing = true
    }
  }

  if (data.charge) {
    data.tid = data.charge.id
    data.type = 'one-time'
  }

  if (window.lp_ident) lp_ident(`CUSTOMER:${data.userId}`)

  if (window.lp_event) {
    
    if (!data.tid) {
      console.log('no transaction id', data)
    }
    const listId = `${data.pageId}`
    const listName = `${data.offerName || data.channel}`
    const currency = (data.currency || 'USD').toUpperCase()
    const coupon = data.coupon

    const params = {
      transaction_id: data.tid,
      currency,
      value: Number(data.price),
      trialing: trialing,
      method: pageMethod,
      version: lpVersion,
      items: [
        {
          item_id: `${data.pageId}`,
          item_name: `${data.domain} ${(data.offerName || data.channel).trim()}`,
          currency: currency,
          item_brand: `${data.domain}`,
          item_category: `${data.period}`,
          item_list_id: `${listId}`,
          item_list_name: `${listName}`,
          coupon,
          index: 0,
          price: Number(data.price),
          quantity: 1,

        }
      ]
    }
    // console.log('purchase', params)
    if (window?.gtag) window?.gtag("event", "purchase", params)
      

    lp_event('paidInviteSignup', { value: data.price });

    lp_event('join_group', { group_id: data.group })


  }
}

const gaEcommEvent = ({ name, formattedPrices}) => {
  if (window?.gtag) {
    window?.gtag("event", name, formattedPrices?.ecom)
  }
}
const beginCheckout = ({ formattedPrices }) => {
  gaEcommEvent({name: "begin_checkout", formattedPrices })
}


const postSubscriptionElements = ({ formattedPrices, provider = 'stripe', purchaseRedirectUrl = null}, version, isEmbed = false) => async (dispatch) => {
  try {
    dispatch(requestSubscription())

    if (window?.elements) {
      const submitResult = await window.elements?.submit();

      if (submitResult.error) {
        dispatch(additionalError({ status: 'error', message: submitResult.error.message }));
        return;
      }
    }

    const {
      form, page, selector, params,
    } = store.getState()
    // TODO: Move to state
    const affiliate = document.URL.split('?id=')[1]
    const {
      email, cardNumber, cvc, date, card, nameOnCard, paymentMethod, coupon,
    } = form

    const {
      pageId, customRedirect, convertCode, customTOS, customSetting1Enabled,
    } = page

    const yearlySelected = selector.yearlySelected ? 'yearly' : 'monthly'
    const paymentOption = page.alternatePayment ? yearlySelected : undefined
    // console.log('got coupon', coupon)
    const validCoupon = coupon?.valid ? coupon?.id : null

    fullstoryInit()
    fullstoryIdentify(email, email, email)

    const pathSegments = window.location.pathname.split('/').filter(Boolean); // Filter to remove empty segments
    let pageMethod = pathSegments[pathSegments.length - 1]; // Get last segment without modifying array

    if (pathSegments.length === 1) {
      pageMethod = 'bp';
    } else if (!['v1', 'v2', 'v3', 'v4', 'iframe', 'embed'].includes(pageMethod)) {
      pageMethod = 'v2';
    }

    const data = {
      email,
      paymentOption,
      pageId,
      affiliate,
      validCoupon,
      nameOnCard,
      provider,
      version,
      isEmbed,
      purchaseRedirectUrl: purchaseRedirectUrl || (new URLSearchParams(window.location.search)?.get('redirect')),
      pageMethod,
      customSetting1Enabled,
    }

    beginCheckout({ formattedPrices })

    const response = await fetch(`${apiUrl}/userElements`, {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      credentials: 'include',
      body: JSON.stringify(data),
    })
    let result = null;
    const results = await response.json()
    // console.log('results', results)
    if (results.status !== 'ok') {
      dispatch(additionalError({
        status: 'error',
        message: results.message,
      }))
      return;
    }
    
      if (provider === 'coinbase') {
        if (results.coinbaseCharge) {
          //console.log('sending to coinbase', results.coinbaseCharge)          
          handleRedirect(results.coinbaseCharge.hosted_url)
          // dispatch(setCoinbaseCharge(results.coinbaseCharge))
          //dispatch(openModal('coinbase', results.coinbaseCharge))
        } else {
          dispatch(additionalError({
            status: 'error',
            message: 'No coinbase charge!',
          }))
        }
        return
      } else {

        if (window?.elements) {
          // const returnUrl = getPortalRedirectUrl(results.authLink)

           //console.log('got results', results.stripePaymentIntent)
          results.clientSecret = results.stripePaymentIntent.client_secret
          if (results.clientSecret.startsWith("seti")) {
            // return document.location = `${document.location.href}?setup_intent=${results.stripePaymentIntent.id}&setup_intent_client_secret=${results.clientSecret}`
            result = await stripe.confirmSetup({
              elements: window.elements,
              clientSecret: results.clientSecret,
              confirmParams: {
                return_url: `${window.location.href}?pageId=${pageId}`,
              },
              redirect: 'if_required',
            });
            //console.log('got result sub', result)
          } else {
            // return document.location = `${document.location.href}?payment_intent=${results.stripePaymentIntent.id}&payment_intent_client_secret=${results.clientSecret}`
            result = await stripe.confirmPayment({
              elements: window.elements,
              clientSecret: results.clientSecret,
              confirmParams: {
                return_url: `${window.location.href}?pageId=${pageId}`,
              },
              redirect: 'if_required',
            });
          }
          //console.log('got result payment', result)
          data.cardSetup = result;
          //console.log('done with elements')
        } else {
          const paymentMethodData = paymentMethod?.paymentMethod?.id || {
            card,
            billing_details: {
              email,
            },
          }

          // if (page.period === 'one-time') {
            //console.log('one time', results.stripePaymentIntent.client_secret)
            if (results.stripePaymentIntent.client_secret.startsWith('seti_')) {
              result = await stripe.confirmCardSetup(results.stripePaymentIntent.client_secret, {
                payment_method: paymentMethodData,
              });
            } else {
              result = await stripe.confirmCardPayment(results.stripePaymentIntent.client_secret, {
                payment_method: paymentMethodData,
              });
            }
          // }
        }
        data.cardSetup = result;
      }

      
      //console.log('got result', result)
      if (result.error) {
        //console.log('got error', result.error)
        if (paymentMethod) paymentMethod.complete('fail')
       
        handlePaymentError(dispatch, result.error, `Oops there was an error: ${result.error.message}`, result);

        //dispatch(additionalError({ status: 'error', message: `Oops there was an error: ${result.error.message}.  If you need additional assistance please contact us at support@launchpass.com` }));
        return;
      }

      data.userId = results.userId;
      const chargeResponse = await fetch(`${apiUrl}/userChargeCard`, {
        method: 'POST',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
        credentials: 'include',
        body: JSON.stringify(data),
      });

      const chargeResponseData = await chargeResponse.json();
      handlePaymentResponse(chargeResponseData, dispatch, isEmbed)
    

  } catch (error) {
    console.error(error)
    // handlePaymentError(dispatch, error, 'An unexpected error occurred while processing your payment.')
    dispatch(additionalError(error))
  }
}

const getCustomerDetailsByIntent = async (intentId, pageId) => {
  const response = await fetch(`${apiUrl}/customer-details`, {
    method: 'POST',
    headers: {
      Accept: 'application/json', 
      'Content-Type': 'application/json',
    },
    credentials: 'include',
    body: JSON.stringify({ intentId, pageId }),
  });
  return response.json();
};
export const handleStripeRedirect = async (dispatch) => {
  dispatch(requestSubscription())
  try {
    const state = store.getState();
    const { form, page, selector } = state;

    let targetPage = page && Object.keys(page).length > 0 ? page : null;

    const urlParams = new URLSearchParams(window.location.search);
    const setupIntent = urlParams.get('setup_intent');
    const paymentIntent = urlParams.get('payment_intent');
    const clientSecret = urlParams.get('setup_intent_client_secret') || urlParams.get('payment_intent_client_secret');
    const pId = targetPage ? targetPage.pageId : urlParams.get('pageId');

    if (!targetPage && state.businessPage && pId) {
      targetPage = state.businessPage.offers.find((item) => item.id === pId)
    }

    if (!clientSecret) {
      console.error('No client secret found in URL');
      handlePaymentError(dispatch, null, 'No client secret found in URL. Please try again.');
      return;
    }

    if (!setupIntent && !paymentIntent) {
      handlePaymentError(dispatch, null, 'No intent found in URL. Please try again.');
      return;
    }

    const intentId = setupIntent || paymentIntent;

    let result;
    try {
      result = await getCustomerDetailsByIntent(intentId, pId);
    } catch (error) {
      console.error('Error retrieving intent:', error);
      handlePaymentError(dispatch, error.error);
      return;
    }

    if (result.error) {
      handlePaymentError(dispatch, result.error);
      return;
    }

    const intent = result.intent //setupIntent || result.paymentIntent;

    // console.log('Returned intent', intent);

    // Only proceed with charge if the intent is succeeded
    if (intent.status !== 'succeeded') {
      handlePaymentError(dispatch, null, `Payment status is ${intent.status}. Please try again.`);
      return;
    }

    // read this from intent metadata
    const {
      customSetting1Enabled,
    } = targetPage;

    // Get page method from URL
    const pathSegments = window.location.pathname.split('/').filter(Boolean);
    let pageMethod = pathSegments[pathSegments.length - 1];
    if (pathSegments.length === 1) {
      pageMethod = 'bp';
    } else if (!['v1', 'v2', 'v3', 'v4', 'iframe', 'embed'].includes(pageMethod)) {
      pageMethod = 'v2';
    }

    // Get payment option based on period and alternatePayment
    const paymentOption = intent?.metadata?.paymentOption

    // Get valid coupon if exists
    const validCoupon = intent?.metadata?.coupon

    // Get affiliate from URL if exists
    const affiliate = intent?.metadata?.affiliate;

    const isEmbed = Boolean(intent?.metadata?.isEmbed);

    const data = {
      email: intent?.customer?.email,
      paymentOption,
      pageId: pId,
      affiliate,
      validCoupon,
      nameOnCard: intent?.metadata?.nameOnCard,
      provider: 'stripe',
      version: intent?.metadata?.version,
      isEmbed,
      purchaseRedirectUrl: intent?.customer?.metadata?.purchaseRedirectUrl,
      pageMethod,
      customSetting1Enabled,
      cardSetup: {
        ...(result.intent.client_secret.startsWith('seti_') ? { setupIntent: result.intent } : { paymentIntent: result.intent }),
      },
      userId: intent.metadata?.userId,
    };

    const chargeResponseData = await makeChargeRequest(data);
    await handlePaymentResponse(chargeResponseData, dispatch, isEmbed);
  } catch (error) {
    console.error('Error handling Stripe redirect:', error);
    handlePaymentError(dispatch, error, 'An unexpected error occurred while processing your payment.');
  }
};

const handlePaymentError = (dispatch, error, message, chargeResponseData) => {

  dispatch(additionalError({
    status: 'error',
    message: message || `Payment failed: ${error.message}. If you need additional assistance please contact us at support@launchpass.com`,
  }));

  // cleanup payment
  /*if (chargeResponseData) {
    fetch(`${apiUrl}/stripe-payment-cleanup`, {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      credentials: 'include',
      body: JSON.stringify({ eventId: chargeResponseData.eventId }),
    }).catch(console.error);
  }*/
};

const handlePaymentSuccess = async (intent, state, isEmbed = false, chargeResponseData) => {
  const { page } = state;

  // Track successful payment
  trackPurchase({
    paymentIntent: intent,
    status: 'succeeded',
    domain: page.domain,
    price: intent.amount / 100, // Convert from cents to dollars
    pageId: page.pageId,
    offerName: page.offerName,
    channel: page.channel,
    period: intent?.metadata?.paymentOption,
    userId: intent?.customer?.email, // Use email as userId if available
  });

  // Redirect to portal
  handlePortalRedirect({
    paymentIntentId: intent.id,
    s: 2,
  }, isEmbed && chargeResponseData.authLink);
};

const processIntent = async (intent, dispatch, isEmbed = false, chargeResponseData) => {
  if (intent.status === 'succeeded') {
    const state = store.getState();
    await handlePaymentSuccess(intent, state, isEmbed, chargeResponseData);
  } else if (intent.status === 'requires_action') {
    const resp = await handleCardAuth(intent);
    if (!resp.error) {
      const state = store.getState();
      await handlePaymentSuccess(resp.paymentIntent, state, isEmbed, chargeResponseData);
    } else {
      handlePaymentError(dispatch, resp.error, `Authentication failed: ${resp.error.message}`);
    }
  } else {
    handlePaymentError(dispatch, null, `Unexpected payment status: ${intent.status}`);
  }
};

const handlePaymentResponse = async (chargeResponseData, dispatch, isEmbed = false) => {
  ///console.log('chargeResponseData', JSON.stringify(chargeResponseData, null, 2))
  if (chargeResponseData.success === false) {
    handlePaymentError(dispatch, null, chargeResponseData.message, chargeResponseData);
    return;
  }

  const intent = chargeResponseData.paymentIntent ?? chargeResponseData.setupIntent;
  if (intent) {
    await processIntent(intent, dispatch, isEmbed, chargeResponseData);
    return;
  }

  if (chargeResponseData.subscription) {
    await handleSubscriptionResponse(chargeResponseData, dispatch, isEmbed);
    return;
  }

  handlePaymentError(dispatch, null, 'Unexpected Error', chargeResponseData);
};

const handleSubscriptionResponse = async (chargeResponseData, dispatch, isEmbed = false) => {
  const subscription = chargeResponseData.subscription;

  if (subscription.status === 'incomplete' && subscription.latest_invoice.payment_intent) {
    const resp = await handleCardAuth(subscription.latest_invoice.payment_intent);
    if (!resp.error) {
      trackPurchase(chargeResponseData);
      handlePortalRedirect({
        subscriptionId: subscription.id,
        s: 2,
      }, isEmbed && chargeResponseData.authLink);
    } else {
      handlePaymentError(dispatch, resp.error);
    }
  } else if (subscription.status === 'trialing') {
    if (subscription.pending_setup_intent) {
      const resp = await handleCardSetupAuth(subscription.pending_setup_intent);
      if (!resp.error) {
        trackPurchase(chargeResponseData);
        handlePortalRedirect({
          subscriptionId: subscription.id,
          s: 2,
        }, isEmbed && chargeResponseData.authLink);
      } else {
        handlePaymentError(dispatch, resp.error);
      }
    } else {
      trackPurchase(chargeResponseData);
      handlePortalRedirect({
        subscriptionId: subscription.id,
        s: 2,
      }, isEmbed && chargeResponseData.authLink);
    }
  } else if (subscription.status === 'active') {
    trackPurchase(chargeResponseData);
    handlePortalRedirect({
      subscriptionId: subscription.id,
      s: 2,
    }, isEmbed && chargeResponseData.authLink);
  } else {
    handlePaymentError(dispatch, null, 'Unexpected Subscription Status');
  }
};

const makeChargeRequest = async (data) => {
  const chargeResponse = await fetch(`${apiUrl}/userChargeCard`, {
    method: 'POST',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
    credentials: 'include',
    body: JSON.stringify(data),
  });

  return chargeResponse.json();
};

export const initializeStripeRedirect = (dispatch) => {
  const urlParams = new URLSearchParams(window.location.search);
  const hasStripeParams = urlParams.has('setup_intent') || 
                         urlParams.has('payment_intent') || 
                         urlParams.has('setup_intent_client_secret') || 
                         urlParams.has('payment_intent_client_secret');

  if (hasStripeParams) {
    return handleStripeRedirect(dispatch);
  } 
  
  window.history.replaceState({}, document.title, window.location.pathname);

  return null;
};

const invalidateSubscription = (form) => {
  const errors = []
  const stateform = store.getState().form
  const page = store.getState().page

  if (page.requireNameEnabled && !(form.nameOnCard?.length > 3)) {
    errors.push({ field: 'nameOnCard', message: 'Please enter the name on your credit card.' })
  }

  if (page.customTOS && !stateform.tosAccepted) {
    // console.log('current form', form, store.getState())
    errors.push({ field: 'customTOS', message: 'Please accept our terms of service.' })
  }
  if (!isEmail(form.email)) {
    errors.push({
      field: 'email',
      message: 'Invalid email',
    })
  }

  if (form.verifyEmail) {
    if (form.email != form.verifyEmail) {
      errors.push({
        field: 'verifyEmail',
        message: 'Email must match',
      })
    }
  }

  if (!Stripe.card.validateCardNumber(form.cardNumber)) {
    errors.push({
      field: 'cardNumber',
      message: 'Invalid card number',
    })
  }

  if (!Stripe.card.validateExpiry(form.date)) {
    errors.push({
      field: 'date',
      message: 'Invalid date',
    })
  }

  if (!Stripe.card.validateCVC(form.cvc)) {
    errors.push({
      field: 'cvc',
      message: 'Invalid CVC',
    })
  }

  return errors
}

export const invalidateSubscriptionElements = ({ provider } = {}, version) => {
  const errors = []
  const { page, form, params } = store.getState()
  // console.log('form', JSON.stringify(form, null, 2))

  if (form.paymentMethod && version?.toLowerCase() !== 'v4') {
    return errors;
  }

  if (page.customTOS && !form.tosAccepted && version?.toLowerCase() !== 'v4') {
    errors.push({ field: 'customTOS', message: 'Please accept our terms of service.' })
  }

  if (page.requireNameEnabled && !(form.nameOnCard?.length > 3) && provider !== 'coinbase') {
    if (version?.toLowerCase() === 'v4' && (form.paymentMethod === 'card' || !form.paymentMethod)) {
      errors.push({ field: 'nameOnCard', message: 'Please enter your first name.' })
    }

    if (version?.toLowerCase() !== 'v4') {
      errors.push({ field: 'nameOnCard', message: 'Please enter your first name.' })
    }
  }

  if (
    !form.email
    || (form.email && form.email.trim().length === 0)
  ) {
    errors.push({
      field: 'email',
      message: 'Please enter your email',
    })
  }

  if (form.email && form.email.trim().length > 0 && !isEmail(form.email)) {
    errors.push({
      field: 'email',
      message: 'Invalid email',
    })
  }

  if (form.email !== form.emailConfirm) {
    errors.push({
      field: 'emailConfirm',
      message: 'Email must match',
    })
  }

  if (params.method === 'credit-card'
    && !form.card?._complete
  ) {
    errors.push({
      field: 'card',
      message: 'Enter your credit card details',
    })
  }

  return errors
}

const invalidateField = (form, errors = [], field) => {
  if (form[field].length === 0) return errors

  if (field === 'email' && !isEmail(form.email)) {
    errors.push({
      field: 'email',
      message: 'Invalid email',
    })
  } else if (field === 'cardNumber' && !Stripe.card.validateCardNumber(form.cardNumber)) {
    errors.push({
      field: 'cardNumber',
      message: 'Invalid card number',
    })
  } else if (field === 'date' && !Stripe.card.validateExpiry(form.date)) {
    errors.push({
      field: 'date',
      message: 'Invalid date',
    })
  } else if (field === 'cvc' && !Stripe.card.validateCVC(form.cvc)) {
    errors.push({
      field: 'cvc',
      message: 'Invalid CVC',
    })
  } else {
    errors = errors.filter((error) => error.field !== field)
  }

  return errors
}

export const validateAndPostSubscriptionElements = (data, version = 'N/A', isEmbed = false) => (dispatch) => {
  if (!data.expressCheckout) {
    const errors = invalidateSubscriptionElements(data, version)
    if (errors.length) {
      return dispatch(displayErrors(errors))
    }
  }

  // Run this only for stripe payment element on v4
  // if (version.toLowerCase() === 'v4' && data.provider === 'stripe') {
    // return dispatch(postSubscriptionElementsV4(data, version, isEmbed))
  // }

  return dispatch(postSubscriptionElements(data, version, isEmbed))
}

export const validateAndPostSubscription = (form) => (dispatch, getState) => {
  const errors = invalidateSubscription(form)
  if (errors.length) {
    return dispatch(displayErrors(errors))
  }

  const state = getState()
  const {
    pageId, alternatePayment, customRedirect, convertCode, currency = 'usd',
  } = state.page
  const yearlySelected = state.selector.yearlySelected ? 'yearly' : 'monthly'
  return dispatch(postSubscription({
    form,
    convertCode,
    paymentOption: alternatePayment ? yearlySelected : undefined,
    pageId,
    pageCurrency: currency,
    customRedirect,
    validCoupon: state.coupon.valid ? state.coupon.id : null,
  }))
}

export const validateFormOnly = (form, field) => (dispatch, getState) => {
  const state = getState()
  if (state.form) {
    const errors = invalidateField(form, state.form.errors, field)
    dispatch(displayErrors(errors))
  } else {
    // https://app.bugsnag.com/codehouse/launchpass/errors/5efed562c456b40017c4a87a?event_id=5efed562005b7803374e0000&i=sk&m=nw
    console.log('state missing form?', state);
  }
}

const invalidateEmail = (form) => (isEmail(form.email) ? [] : [{ field: 'email', message: 'Invalid email' }])

const postEmail = (form, pageId, customRedirect, convertCode) => async (dispatch) => {
  try {
    dispatch(requestSubscription())

    const { email } = form

    const data = {
      email,
      pageId,
    }

    const response = await fetch(`${apiUrl}/userElements`, {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      credentials: 'include',
      body: JSON.stringify(data),
    })

    const results = await response.json()

    if (results.status === 'ok') {
      if (convertCode) {
        eval(decodeURIComponent(convertCode))
        // let the code run for 5 seconds before redirect
        // await new Promise((resolve) => setTimeout(resolve, 5000));
      }
      if (results.discordInviteLink) {
        return window.location.replace(results.discordInviteLink)
      }
      if (customRedirect) {
        window.location.replace(customRedirect.startsWith('http') ? customRedirect : `http://${customRedirect}`)
      } else {
        dispatch(receiveSubscription(results))
      }
    } else {
      dispatch(additionalError(results))
    }
  } catch (error) {
    console.error(error)
    dispatch(additionalError(error))
  }
}

export const validateAndPostEmail = (form, page) => (dispatch, getState) => {
  dispatch(updateFormField('email', form.email))
  const errors = invalidateEmail(form)
  if (errors.length) {
    return dispatch(displayErrors(errors))
  }

  if (page) {
    return dispatch(postEmail(form, page.id, page.customRedirect, page.convertCode))
  }

  const state = getState()
  return dispatch(postEmail(form, state.page.pageId, state.page.customRedirect, state.page.convertCode))
}

// Coupons

export const validateCoupon = (coupon) => async (dispatch, getState) => {
  try {
    const state = getState()
    dispatch(couponValidating(true))
    const response = await fetch(`${apiUrl}/coupon`, {
      method: 'post',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        sp: state.page.pageId,
        couponCode: coupon,
      }),
    })

    const couponData = await response.json()

    if (!couponData.valid) {
      dispatch(couponInvalid())
    } else {
      // console.log('got coupon', couponData)
      dispatch(updateFormField('coupon', couponData))
      dispatch(couponValid(couponData))
    }

    dispatch(couponValidating(false))
  } catch (error) {
    console.log(error)
  }
}

export const processCoinbasePayment = (data, charge) => async (dispatch, getState) => {
  const { event } = data

  const chargeLink = `${document.location.origin}/portal?s=2`

  if(event === 'payment_detected' || event === 'charge_confirmed')
    handleRedirect(chargeLink)
  else if(event === 'error_not_found')
    dispatch(additionalError({
      status: 'error',
      message: 'Coinbase charge error!'
    }))
  else if(event === 'checkout_modal_closed'){
    store.dispatch(displayErrors([]))
    store.dispatch(openModal('coinbase-close', { chargeLink }))
    // store.dispatch(updateFormField('email', ''))
    // store.dispatch(updateFormField('emailConfirm', ''))
    // store.dispatch(setCoinbaseCharge(null))
  }
}
