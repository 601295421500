import React from 'react';
import PropTypes from 'prop-types';
import Spinner from '../clientAdmin/components/Spinner';
import { CloseIcon } from './Icons';

function StripeRedirectModal({
  isOpen,
  onClose,
  status,
  errorMessage,
}) {
  if (!isOpen) return null;

  const isLoading = status === 'loading';
  const isError = status === 'error';
  const isSuccess = status === 'success';

  return (
    <div className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50">
      <div className="w-auto md:w-[720px] min-h-[590px] bg-white rounded-lg shadow-xl overflow-hidden">
        <div className="relative p-6">
          {!isLoading && (
            <button
              className="absolute top-4 right-4 text-gray-400 hover:text-gray-600 focus:outline-none"
              onClick={onClose}
              type="button"
              aria-label="Close"
            >
              <CloseIcon />
            </button>
          )}
          <div className="flex flex-col items-center justify-center gap-4 w-full">
            {isLoading && (
              <>
                <Spinner color="#545c63" size={40} />
                <div className="text-center mt-4">
                  <h3 className="text-lg font-semibold mb-2 text-gray-800">Processing Payment</h3>
                  <p className="text-gray-600">Please wait while we process your payment...</p>
                </div>
              </>
            )}
            {isError && (
              <>
                <div className="text-red-500 mb-2">
                  <svg xmlns="http://www.w3.org/2000/svg" width="48" height="48" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">
                    <circle cx="12" cy="12" r="10" />
                    <line x1="15" y1="9" x2="9" y2="15" />
                    <line x1="9" y1="9" x2="15" y2="15" />
                  </svg>
                </div>
                <div className="text-center">
                  <h3 className="text-lg font-semibold text-red-500 mb-2">Payment Failed</h3>
                  <p className="text-gray-600 mb-4">{errorMessage || 'There was an error processing your payment.'}</p>
                  <button
                    className="w-full py-3 px-4 rounded-md bg-red-500 hover:bg-red-600 mt-4 max-w-[300px] text-white font-medium transition-colors focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500"
                    onClick={onClose}
                    type="button"
                  >
                    Try Again
                  </button>
                </div>
              </>
            )}
            {isSuccess && (
              <>
                <div className="text-green-500 mb-2">
                  <svg xmlns="http://www.w3.org/2000/svg" width="48" height="48" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">
                    <path d="M22 11.08V12a10 10 0 1 1-5.93-9.14" />
                    <polyline points="22 4 12 14.01 9 11.01" />
                  </svg>
                </div>
                <div className="text-center">
                  <h3 className="text-lg font-semibold text-green-500 mb-2">Payment Successful</h3>
                  <p className="text-gray-600 mb-4">Your payment has been processed successfully.</p>
                  <button
                    className="w-full py-3 px-4 rounded-md bg-green-500 hover:bg-green-600 text-white font-medium transition-colors focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-500"
                    onClick={onClose}
                    type="button"
                  >
                    Continue
                  </button>
                </div>
              </>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

StripeRedirectModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  status: PropTypes.oneOf(['loading', 'success', 'error']).isRequired,
  errorMessage: PropTypes.string,
};

StripeRedirectModal.defaultProps = {
  errorMessage: '',
};

export default StripeRedirectModal; 
